<template>
  <section class="content">
    <div class="text">
      <h1>Payment cancelled</h1>
      <p>Please contact us if you need assistance.</p>
    </div>
  </section>
</template>

<script>
export default {
  created() {}
}
</script>
